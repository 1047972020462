.featureAreaRoot {
  margin-top: 0;
  /* padding: 40px; */
  margin-bottom: 40px;
}

.featureAreaContainer {
  display: flex;
  flex-direction: column;
  gap: 60px;
}

.featureAreaHeader {
  font-size: 28px;
  font-weight: bold;
  text-align: left;
  color: #a792b0;
}
.featureAreaCards {
  display: flex;
  justify-content: space-between;
}

.cardRoot {
  width: 200px;
  height: 200px;
  border: 1px solid #a792b0;
}
.cardContainer {
  text-align: center;
  align-items: center;
}
.cardImageContainer {
  width: 200px;
  object-fit: cover;
}
.cardImage {
  height: 100%;
  width: 100%;
}
.cardHeader {
  font-weight: bold;
  font-size: 18px;
  margin-top: 10px;
  color: #a792b0;
}
.cardText {
  opacity: 0.7;
}
